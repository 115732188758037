/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');
        // Mobile Navigation
        $('.c-toggle--nav').click(function(e) {
            $(this).toggleClass('is-active');

            $('.c-nav--mobile .c-nav__list').slideToggle();
        });
        //
        //Full With Images
        //
        var colDesktop1 = $('.c-content--intro').data('desktop'),
            colDesktop2 = $('.c-content--attractions').data('desktop'),
            colDesktop3 = $('.c-content--reviews').data('desktop'),
            colMobile1 = $('.c-content--intro').data('mobile'),
            colMobile2 = $('.c-content--attractions').data('mobile'),
            colMobile3 = $('.c-content--reviews').data('mobile');
        if(viewportWidth < 1024 ){
            $('.c-content--intro').css('background-image', 'url('+colMobile1+')');
            $('.c-content--attractions').css('background-image', 'url('+colMobile2+')');
            $('.c-content--reviews').css('background-image', 'url('+colMobile3+')');
        }else{
            $('.c-content--intro').css('background-image', 'url('+colDesktop1+')');
            $('.c-content--attractions').css('background-image', 'url('+colDesktop2+')');
            $('.c-content--reviews').css('background-image', 'url('+colDesktop3+')');
        }
        //
        //Half Width Images
        //
        var colDesktop4 = $('.c-content--interest .o-col--first').data('desktop'),
            colDesktop5 = $('.c-content--amenities .o-col--second').data('desktop'),
            colMobile4 = $('.c-content--interest .o-col--first').data('mobile'),
            colMobile5 = $('.c-content--amenities .o-col--second').data('mobile');
        if(viewportWidth < 1024 ){
            $('.c-content--interest .o-col--first').css('background-image', 'url('+colMobile4+')');
            $('.c-content--amenities .o-col--second').css('background-image', 'url('+colMobile5+')');
        }else{
            $('.c-content--interest .o-col--first').css('background-image', 'url('+colDesktop4+')');
            $('.c-content--amenities .o-col--second').css('background-image', 'url('+colDesktop5+')');
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
